.version_2{
    .block-banner{
        position: relative;
        .block-content{
            .banner-back{
                width: 100%;
                height: 520px;
                background: url('https://data.wedongmi.com/xinpi/official_website/png/banner_mobile.png') center/cover;
            }
        }
        .login-content{
            width: 1200px;
            position: absolute;
            left: 50%;
            margin-left: -600px;
            top: 164px;
            display: flex;
            justify-content: flex-end;
            .left-info{
                padding-right: 160px;
                div{
                    text-align: center;
                }
                .left-info-title{
                    color: #FFFFFF;
                    line-height: 62px;
                    font-weight: bold;
                    font-size: 44px;
                }
                .left-info-title-small{
                    color: #FFFFFF;
                    line-height: 28px;
                    font-size: 20px;
                    margin-top: 8px;
                }
                .left-info-btn{
                    margin: 20px auto 20px;
                    width: 184px;
                    height: 52px;
                    background: url("../../assets/phone/icon_phone.png") no-repeat center center;
                    background-size: 100%;
                    padding-left: 55px;
                    line-height: 52px;
                    font-size: 18px;
                    // font-weight: bold;
                    color: #FFFFFF;
                    text-align: left;
                    cursor: pointer;
                }
            }
        }
    }
    .block-five{
        background-color: #ffffff;
        padding-bottom: 120px;
        .list-five{
            width: 1200px;
            margin: 0 auto;
            .item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 34px 0;
                .content{
                    .parentItem{
                        .itemTitle{
                            color: #1D2129;
                            line-height: 56px;
                            font-size: 40px;
                        }
                        .itemContent{
                            margin-top: 24px;
                            div{
                                color: #4E5969;
                                line-height: 22px;
                                font-size: 16px;
                                margin-bottom: 8px;
                            }
                            div:nth-last-child(1){
                                margin-bottom: 0;
                            }
                        }
                    }
                    .parentItemAll{
                        .itemContent{
                            margin-top: 8px;
                        }
                    }
                    .parentItemAll{
                        margin-top: 40px;
                    }
                    .parentItemAll:nth-last-child(1){
                        margin-bottom: 0;
                    }
                }
                .img{
                    width: 541px;
                    height: 540px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    img{
                        width: 541px;
                    }
                }
            }
        }
    }
}
.popover{
    img{
        width: 110px;
    }
    div{
        text-align: center  ;
        color:#1D2129;
        line-height: 20px;
        font-size: 14px;
        margin-top: 12px;
    }
}
