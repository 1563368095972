.dongmi {
    width: 100%;
    height: 100vh;
    background: url('https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/login_bg.png')
        no-repeat center center;
    background-size: 100% 100%;
    position: relative;
    .goBack{
        position: absolute;
        left: 30px;
        top: 32px;
        cursor: pointer;
        width: 114px;
        height: 36px;
        border-radius: 22px;
        border: 1px solid #1E9BFF;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1E9BFF;
        font-size: 14px;
        img{
            margin-right: 5px;
        }
    }
    .contentMain {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content {
        display: flex;
        flex-direction: column;
        margin-top: -60px;
        .titbox {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
            img {
                width: 85px;
                height: 25px;
            }
            span {
                margin: 0 15px;
                font-size: 22px;
            }
            p {
                color: #4e5969;
                font-size: 22px;
                margin: 0;
            }
        }
        .tryuse {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 920px;
            height: 529px;
            background: #ffffff;
            box-shadow: 0px 5px 30px 0px rgba(110, 172, 239, 0.2);
            border-radius: 12px;
            padding: 36px;
            position: relative;
            h2 {
                font-weight: bold;
                color: #1d2129;
                font-size: 24px;
            }
            .tryuse-content{
                width: 358px;
                .login-form-button{
                    width: 100%;
                    border-radius: 8px;
                    background: #1E9BFF;
                }
                .tryuse-desc{
                    text-align: center;
                }
        
            }
            .tryuse-back{
                position: absolute;
                top: 36px;
                left: 36px;
                cursor: pointer;
            }
        }
        .wrap {
            display: flex;
            justify-content: space-between;
            width: 920px;
            height: 529px;
            background: #ffffff;
            box-shadow: 0px 5px 30px 0px rgba(110, 172, 239, 0.2);
            border-radius: 12px;
            padding: 51px;
            .left {
                width: 358px;
                display: flex;
                flex-direction: column;
                align-items: center;
                h3 {
                    font-weight: bold;
                    color: #1d2129;
                    font-size: 24px;
                    span {
                        color: #1e9bff;
                    }
                }
                p {
                    color: #86909c;
                    font-size: 14px;
                    span {
                        color: #4e5969;
                    }
                }
                .arcode {
                    margin-left: auto;
                    padding: 45px 36px 46px;
                    .failure {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-content: center;
                        p {
                            width: 100%;
                            text-align: center;
                            color: rgba(0, 0, 0, 0.85);
                        }
                        p:nth-child(2) {
                            color: #fff;
                            width: 60px;
                            height: 24px;
                            margin-top: 8px auto 0;
                            background: #1ec162;
                            border-radius: 4px;
                            display: flex;
                            font-size: 12px;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            i {
                                margin-right: 3px;
                            }
                        }
                        > div {
                            height: 212px;
                            width: 212px;
                            border: 1px solid #d9d9d9;
                            padding: 9px;
                            position: relative;
                            img {
                                width: 188px;
                                height: 188px;
                                left: 9px;
                                top: 9px;
                                position: absolute;
                            }
                            div {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                align-content: center;
                                background: rgba(255, 255, 255, 0.85);
                                position: relative;
                                z-index: 2;
                            }
                        }
                    }

                    .saoma {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        > div:nth-last-child(1) {
                            border: 1px solid #d9d9d9;
                        }
                        img {
                            width: 160px;
                        }
                    }
                }
            }
            .right {
                width: 410px;
                padding-left: 51px;
                background: #ffffff;
                border-radius: 8px;
                position: relative;
                border-left: 1px solid #f0f1f2;
                .loge {
                    text-align: center;
                }
                .tip {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 24px;
                    div {
                        width: 96px;
                        height: 2px;
                        background: linear-gradient(
                            90deg,
                            #c9cdd4 0%,
                            #f6faff 100%
                        );
                        border-radius: 3px;
                    }
                    div:nth-child(1) {
                        background: linear-gradient(
                            90deg,
                            #f6faff 0%,
                            #c9cdd4 100%
                        );
                    }
                    span {
                        margin: 0 16px;
                        font-size: 16px;
                        color: #1d2129;
                        line-height: 22px;
                    }
                }
            }
        }
    }
    .footer {
        position: absolute;
        bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        line-height: 22px;
        width: 100%;
        img {
            margin-right: 4px;
        }
        span {
            display: inline-flex;
            align-items: center;
            margin: 8px 10px;
        }
        span:nth-child(1) {
            margin-left: 0;
        }
        a {
            color: #fff;
        }
        a:hover {
            color: #40a9ff;
        }
    }
}

