.version_3{
    .block-banner{
        position: relative;
        .block-content{
            .banner-back{
                width: 100%;
                height: 520px;
                background: url('https://data.wedongmi.com/xinpi/official_website/png/banner_ipo.png') center/cover;
            }
        }
        .login-content{
            width: 1200px;
            position: absolute;
            left: 50%;
            margin-left: -600px;
            top: 212px;
            display: flex;
            justify-content: center;
            .left-info{
                div{
                    text-align: center;
                }
                .left-info-title{
                    color: #FFFFFF;
                    line-height: 62px;
                    font-weight: bold;
                    font-size: 44px;
                }
                .left-info-title-small{
                    color: #FFFFFF;
                    line-height: 28px;
                    font-size: 20px;
                    margin-top: 7px;
                }
            }
        }
    }
    .block-one{
        background-color: #dee4f8;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        .block-content{
            width: 100%;
           .banner-back{
                width: 100%;
                height: 774px;
                background: url('https://data.wedongmi.com/xinpi/official_website/png/ipo_function.svg') center/cover;
                position: relative;
                .title{
                    text-align: center;
                    font-weight: 500;
                    color: rgba(0,0,0,0.85);
                    line-height: 45px;
                    font-size: 32px;
                    position: absolute;
                    top: 56px;
                    width: 100%;
                    z-index: 2;
                }
           }
        }
        .slick-dots{
            li.slick-active button, li.slick-active{
                background: #2EA7E0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }
            li button, li{
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: rgba(46, 167, 224, 0.3);
            }
            bottom: -0;
        }
        
    }
    .block-two{
        background-color: #ffffff;
        padding: 56px 0 80px;
        .block-content{
            .title{
                font-weight: bold;
                color: #1D2129;
                line-height: 45px;         
                font-weight: 500;
                font-size: 32px;
                text-align: center;
            }
            .list{
                width: 1200px;
                margin: 0 auto;
                display: flex;
                margin-top: 56px;
                justify-content: space-between;
                .item{
                    width: 288px;
                    border-radius: 12px 12px 0px 0px;
                    >div:nth-child(1){
                        height: 160px;
                        padding-top: 27px;
                        text-align: center;
                        div{
                            font-weight: bold;
                            color: #4E5969;
                            line-height: 28px;
                            font-size: 20px;
                            margin-top: 16px;
                            text-align: center;
                        }
                    }
                    >div:nth-child(2){
                        border-radius: 0 0 12px 12px;
                        height: 144px;
                        padding: 25px 24px 0;
                        color: #FFFFFF;
                        line-height: 24px;
                        font-size: 14px;
                    }
                }
                .item:nth-child(1){
                    >div:nth-child(1){
                        background: #F1F6FF;
                    }
                    >div:nth-child(2){
                        background: linear-gradient(90deg, #73A0FA 0%, #558EFF 100%);
                    }
                }
                .item:nth-child(2){
                    >div:nth-child(1){
                        background: #EFF8FD;
                    }
                    >div:nth-child(2){
                        background: linear-gradient(90deg, #5CC1EF 0%, #4CAEE7 100%);
                    }
                }
                .item:nth-child(3){
                    >div:nth-child(1){
                        background: #F3F2FC;
                    }
                    >div:nth-child(2){
                        background: linear-gradient(90deg, #978EFA 0%, #7C6AD6 100%);
                    }
                }
                .item:nth-child(4){
                    >div:nth-child(1){
                        background: #F0F2F7;
                    }
                    >div:nth-child(2){
                        background: linear-gradient(90deg, #637DB2 0%, #5972A3 100%);
                    }
                }
            }
        }
    }
    .block-five{
        padding: 60px 0;
        .list-five{
            width: 1200px;
            margin: 0 auto;
            .item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 104px 0;
                .content{
                    .itemTitle{
                        color: #1D2129;
                        line-height: 56px;
                        font-size: 40px;
                    }
                    .itemContent{
                        margin-top: 24px;
                        div{
                            color: #4E5969;
                            line-height: 22px;
                            font-size: 16px;
                            margin-bottom: 8px;
                        }
                        div:nth-last-child(1){
                            margin-bottom: 0;
                        }
                    }
                    .try{
                        width: 100px;
                        height: 36px;
                        background: linear-gradient(180deg, #2EBCFF 0%, #1579FF 100%);
                        border-radius: 22px;
                        color: #FFFFFF;
                        font-size: 14px;
                        text-align: center;
                        line-height: 36px;
                        cursor: pointer;
                        margin-top: 40px;
                    }
                }
                .img{
                    width: 620px;
                    height: 424px;
                    // background: url("../../assets/ipo/icon_back_5.png") no-repeat center center;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    img{
                        width: 620px;
                    }
                }
                .left{
                    img{
                        width: 511px;
                    }
                }
                .right{
                    div{
                        display: flex;
                        align-items: center;
                        img{
                            margin-right: 10px;
                        }
                        margin-bottom: 12px;
                        color: #4E5969;
                        line-height: 22px;
                        font-size: 16px;
                    }
                    div:nth-last-child(1){
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .block-four{
        background: url("https://data.wedongmi.com/xinpi/official_website/png/bg_4.png") no-repeat center center;
        background-size: cover;
        height: 735px;
        width: 100%;
        padding: 56px 0 0;
        display: flex;
        justify-content: center;
        .block-content{
            width: 1200px;
            position: relative;
            .title{
                text-align: center;
                font-weight: 500;
                color: #fff;
                line-height: 45px;
                font-size: 32px;
            }
            .tip{
                font-weight: 400;
                color: #fff;
                text-align: center;
                line-height: 22px;
                font-size: 16px;
                margin-top: 10px;
            }
            .list{
                margin-top: 60px;
                .list-item{
                    margin-bottom: 51px;
                    display: flex;
                    align-items: center;
                    img{
                        margin-right: 6px;
                    }
                    .list-item-content{
                        div:nth-child(1){
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 25px;
                            font-size: 18px;
                        }
                        div:nth-child(2){
                            color: #FFFFFF;
                            line-height: 22px;
                            font-size: 16px;
                            margin-top: 6px;
                            opacity: 0.6;
                        }
                    }
                }
                .list-item:nth-last-child(1){
                    margin-bottom: 0;
                }
            }
            .img{
                position: absolute;
                right: 0;
                top: 160px;
            }
        }
    }
}

