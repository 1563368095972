footer {
    background: #fff;
    overflow: hidden;
    .beian{
        margin-bottom: 32px;
        font-size: 12px;
        color: rgba(78, 89, 105, 0.6);
        border-bottom: none;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            margin-right: 4px;
        }
        span{
            display: inline-flex;
            align-items: center;
            margin:8px 10px;
        }
        span:nth-child(1){
            margin-left: 0;
        }
        a{
            color: rgba(78, 89, 105, 0.6);
        }
        a:hover {
            color: #40a9ff;
        }
    }
    .footer-content {
        width: 1200px;
        >div{
            display: flex;
        }
        .footer-left{
            display: block;
            >div{
                display: flex;
                border-bottom: 1px solid #F0F1F2;
            }
            >div:nth-last-child(1){
                margin-top: 32px;
                font-size: 12px;
                color: rgba(78, 89, 105, 0.6);
                align-items: center;
                img{
                    margin-right: 4px;
                }
                span{
                    display: inline-flex;
                    align-items: center;
                    margin:8px 10px;
                }
                span:nth-child(1){
                    margin-left: 0;
                }
                a{
                    color: rgba(78, 89, 105, 0.6);
                }
                a:hover {
                    color: #40a9ff;
                }
            }
        }
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 30px 0;
    }
    .footer_code{
        .footer-ul + .footer-ul{
            margin-left: 30px;
        }
    }
    .footer-center {
        color: #4E5969;
        font-size: 12px;
        text-align: center;
        img{
            width: 100px;
        }
    }
    .footer-ul{
        padding-left: 0;
    }
    .footer-ul + .footer-ul {
        margin-left: 80px;
    }
    
    .footer-title {
        margin-bottom: 11px;
        color: #4E5969;
        font-size: 14px;
        opacity: 1;
    }
    
    .footer-item {
        color: rgba(78, 89, 105, 0.6);
        font-size: 14px;
        line-height: 22px;
    }
}
