.header{
    width: 100%;
    .nav-top{
       position: fixed;
       width: 100%;
       top: 0;
       left: 0;
       background: rgba(0, 0, 0, 0.7);
       height: 58px; 
       z-index: 100;
    }
    .nav {
        margin: 0 auto;
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
       
        > img{
            width: 73px;
            margin-right: 100px;
        }
        .nav-menu{
            display: flex;
            align-items: center;
            height: 100%;
            ul {
                padding: 19px 0 6px;
                margin: 0;
                height: 100%;
                li {
                    display: inline-block;
                    font-size: 14px;
                    width: 88px;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.8);
                    position: relative;
                    height: 100%;
                    line-height: 20px;
                    button, a{
                        border-radius: 6px;
                        padding: 3px 14px;
                        height: 30px;
                        border: 1px solid #FFFFFF;
                        background: rgba(255, 255, 255, 0);
                    }
                    >span{
                        display: none;
                        width: 44px;
                        height: 2px;
                        background: #fff;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        margin-left: -22px;
                    }
                }
                li + li {
                    margin-left: 30px;
                }
                li:hover {
                    color: #fff;
                    cursor: pointer;
                    >span{
                        display: inline-block;
                    }
                }
                .nav-title-active{
                    >span{
                        display: inline-block;
                    }
                    color: #fff;
                    font-weight: bold;
                }
                .login{
                    color: #fff;
                }
            }
            .nav-login{
                cursor: pointer;
                width: 100px;
                height: 36px;
                background: linear-gradient(180deg, #2EBCFF 0%, #1579FF 100%);
                border-radius: 22px;
                color: #FFFFFF;
                font-size: 14px;
                line-height: 36px;
                text-align: center;
                margin-left: 50px;
            }
        }
      
        
    }
}
.down{
    div{
        line-height: 40px;
        color:#4E5969;
        cursor: pointer;
    }
    div:hover{
        color: #2EA7E0;
    }
}
