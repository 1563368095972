.version_1{
    .block-banner{
        position: relative;
        .block-content{
            .banner-back{
                width: 100%;
                height: 520px;
                background: url('https://data.wedongmi.com/xinpi/official_website/png/banner.png') center/cover;
            }
        }
        .login-content{
            width: 1200px;
            position: absolute;
            left: 50%;
            margin-left: -600px;
            top: 170px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            .tip{
                text-align: center;
                .bigTitle{
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 50px;
                    letter-spacing: 5px;
                    font-size: 36px;
                }
                .smallTitle{
                    color: #FFFFFF;
                    line-height: 25px;
                    font-size: 18px;
                    margin-top: 18px;
                }
                .try{
                    width: 180px;
                    height: 44px;
                    background: linear-gradient(180deg, #2EBCFF 0%, #1579FF 100%);
                    border-radius: 22px;
                    color: #FFFFFF;
                    font-size: 16px;
                    line-height: 44px;
                    text-align: center;
                    margin: 32px auto 0;
                    cursor: pointer;
                }
            }
            // .left-info{
            //     flex: 0 1 740px;
            //     position: relative;
            //     .left-info-detail{
            //         position: absolute;
            //         bottom: 0;
            //         width: 738px;
            //         height: 318px;
            //         left: 0;
            //         z-index: 3;
            //         background-color: #fff;
            //         .left-img-left{
            //             position: absolute;
            //             left: -120px;
            //             bottom: 108px;
            //         }
            //         .left-img-right{
            //             position: absolute;
            //             right: -60px;
            //             bottom: 108px;
            //             z-index: 2;
            //         }
            //         .left-left-1{
            //             position: absolute;
            //             left: -46px;
            //             top: 32px;
            //             z-index: 4;
            //         }
            //         .left-left-2{
            //             position: absolute;
            //             left: -51px;
            //             bottom: 38px;
            //             z-index: 4;
            //         }
            //         .left-right-1{
            //             position: absolute;
            //             right: -32px;
            //             top: -43px;
            //             z-index: 4;
            //         }
            //         .left-right-2{
            //             position: absolute;
            //             right: -66px;
            //             bottom: 49px;
            //             z-index: 4;
            //         }
            //         .left-info-detail-list{
            //             width: 100%;
            //             height: 100%;
            //             display: flex;
            //             flex-wrap: wrap;
            //             background: #fff;
            //             .left-info-detail-item{
            //                 position: relative;
            //                 overflow: hidden;
            //                 img{
            //                     cursor: pointer;
            //                 }
            //                 >div{
            //                     position: absolute;
            //                     height: 0;
            //                     transition: all 0.3s;
            //                     left: 0;
            //                     bottom: 0;
            //                     width: 100%;
            //                     color: rgba(255,255,255,0.85);
            //                     line-height: 22px;
            //                     font-size: 14px;
            //                     overflow: hidden;
            //                     div{
            //                         width: 100%;
            //                         height: 100%;
            //                     }
            //                 }
            //             }
            //             .left-info-detail-item:hover{
            //                 div{
            //                     height: 100%;
            //                 }
            //             }
            //         }
            //     }
               
            //     // .left-info-title{
            //     //     color: #FFFFFF;
            //     //     line-height: 90px;
            //     //     font-weight: 800;
            //     //     font-size: 60px;
            //     // }
            //     // .left-info-content{
            //     //     margin-top: 28px;
            //     //     .left-info-item{
            //     //         display: flex;
            //     //         .info-item{
            //     //             border-radius: 4px;
            //     //             padding: 32px 28px 32px 34px;
            //     //             .info-item-title{
            //     //                 display: flex;
            //     //                 align-items: center;
            //     //                 img{
            //     //                     margin-right: 24px;
            //     //                 }
            //     //                 div{
            //     //                     font-weight: bold;
            //     //                     color: #FFFFFF;
            //     //                     line-height: 28px;
            //     //                     font-size: 20px;
            //     //                 }
            //     //             }
            //     //             .info-item-content{
            //     //                 font-weight: 400;
            //     //                 color: rgba(255, 255, 255, 0.85);
            //     //                 line-height: 20px;
            //     //                 font-size: 14px;
            //     //                 margin-top: 12px;
            //     //             }
            //     //         }
            //     //         .info-item:nth-child(1){
            //     //             width: 292px;
            //     //             background-color: rgba(48, 117, 237, 0.9);
            //     //         }
            //     //         .info-item:nth-child(2){
            //     //             width: 436px;
            //     //             margin-left: 12px;
            //     //             background-color: rgba(36, 90, 232, 0.9);
            //     //         }
            //     //         .info-left{
            //     //             margin-top: 12px;
            //     //             width: 436px;
            //     //             .info-item{
            //     //                 padding: 13px 28px 13px 34px;
            //     //                 width: 100%;
            //     //                 display: flex;
            //     //                 align-items: center;
            //     //                 margin-left: 0;
            //     //                 .info-item-title{
            //     //                     margin-left: 20px;
            //     //                     margin-left: 0;
            //     //                 }
            //     //                 .info-item-content{
            //     //                     margin-top: 0;
            //     //                     div{
            //     //                         font-weight: bold;
            //     //                         color: #FFFFFF;
            //     //                         line-height: 28px;
            //     //                         font-size: 20px;
            //     //                         margin-bottom: 6px;
            //     //                     }
            //     //                 }
            //     //             }
            //     //             .info-item:nth-child(2){
            //     //                 margin-top: 12px;
            //     //             }
            //     //         }
            //     //         .info-right{
            //     //             margin-top: 12px;
            //     //             width: 292px;
            //     //             margin-left: 12px;
            //     //             .info-item{
            //     //                 width: 100%;
            //     //                 height: 100%;
            //     //                 background-color: rgba(46, 167, 224, 0.9)
            //     //             }
            //     //         }
            //     //     }
            //     // }
            // }
            // .right-login{
            //     background-color: #fff;
            //     border-radius: 8px;
            //     margin-left: 30px;
            //     flex: 1;
            //     border-radius: 8px;
            //     // border: 1px solid #2EA7E0;
            // }
        }
    }
    .block-one{
        background-color: #fff;
        width: 100%;
        padding: 56px 0 60px;
        display: flex;
        justify-content: center;
        .block-content{
            width: 1200px;
            .title{
                text-align: center;
                font-weight: bold;
                color: rgba(0,0,0,0.85);
                line-height: 45px;
                font-size: 32px;
            }
            .tip{
                font-weight: 400;
                color: #86909C;
                text-align: center;
                line-height: 28px;
                font-size: 16px;
                margin-top: 8px;
            }
            .tabs{
                margin-top: 32px;
                .tabs-nav{
                    background: #F9F9FB;
                    width: 100%;
                    height: 132px;
                    position: relative;
                    .tabs-item{
                        height: 100%;
                        display: flex;
                        .tabItem{
                            width: 20%;
                            height: 100%;
                            text-align: center;
                            cursor: pointer;
                            padding-top: 30px;
                            div{
                                font-weight: bold;
                                color: #1D2129;
                                line-height: 20px;
                                font-size: 14px;
                                margin-top: 8px;
                            }
                        }
                    }
                    .tabs-active{
                        width: 20%;
                        height: 2px;
                        background: #1E9BFF;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        transition: all 0.3s;
                    }
                }
                .tabs-content{
                    display: flex;
                    margin-top: 30px;
                    .tabContent{
                        height: 354px;
                        border-radius: 12px;
                        background: #fff;
                        padding: 24px 0;
                        margin-right: 16px;
                        width: 288px;
                        cursor: pointer;
                        position: relative;
                        overflow: hidden;
                        .tabSpace{
                            background: #F9F9FB;
                            height: 100%;
                            border-radius: 12px;   
                            padding: 72px 20px 40px 20px;   
                            position: relative;  
                            .contentNum{
                                position: absolute;
                                top: 33px;
                                left: 20px;
                                img{
                                    width: 59px;
                                }
                            }
                            .name{
                                font-weight: bold;
                                color: #1D2129;
                                line-height: 25px;
                                font-size: 18px;
                            }    
                            .content{
                                margin-top: 17px;
                                color: #86909C;
                                line-height: 22px;
                                font-size: 14px;
                            }
                        }
                        .backimg{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            border-radius: 12px;
                            top: 354px;
                            left: 0;
                            transition: all 0.3s;
                            background: #1E9BFF;
                        }
                    }
                    .tabContentActive{
                        .backimg{
                            top: 0;
                        }
                        .tabSpace{
                            background: rgba(255, 255, 255, 0);
                            .name, .content{
                                color: #fff;
                            }
                        }
                    }
                    .tabContent:nth-last-child(1){
                        margin-right: 0;
                    }
                }
                // .tab-content{
                //     display: flex;
                //     padding-bottom: 40px;
                //     .tab-content-item{
                //         width: 288px;
                //         height: 306px;
                //         border: 2px solid #fff;
                //         padding: 12px 20px;
                //         margin-right: 16px;
                //         .name{
                //             font-weight: bold;
                //             color: rgba(0,0,0,0.85);
                //             line-height: 25px;
                //             font-size: 18px;
                //             margin-top: 6px;
                //         }
                //         .content{
                //             font-weight: 400;
                //             color: #86909C;
                //             line-height: 24px;
                //             font-size: 16px;
                //             margin-top: 11px;
                //         }
                //     }
                //     .tab-content-item:hover{
                //         border: 2px solid #2EA7E0;
                //     }
                //     .tab-content-item:nth-child(4n){
                //         margin-right: 0;
                //     }
                //     .tab-content-item:nth-child(n+5){
                //         margin-top: 16px;
                //     }
                // }
            }
        }
        .slick-dots{
            li.slick-active button, li.slick-active{
                background: #2EA7E0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }
            li button, li{
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: rgba(46, 167, 224, 0.3);
            }
            bottom: -0;
        }
        
    }
    .block-two{
        background: url("https://data.wedongmi.com/xinpi/official_website/png/bg_2.png") no-repeat center center;
        background-size: 100%;
        height: 800px;
        display: flex;
        padding-top: 70px;
        justify-content: center;
        .block-content{
          width: 1200px;
            .ant-tabs-nav{
                width: 208px;
                margin: 0 auto;
                .ant-tabs-nav-list{
                    .ant-tabs-tab{
                        // margin: 0 20px!important;
                        padding: 16px 20px;
                    }
                }
            }
            .carousel{
                margin-top: 72px;
                .carouselItem{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    border-radius: 8px;
                    overflow: hidden;
                    > div{
                        display: flex;
                        align-items: center;
                        width: 100%;
                        > img{
                            cursor: pointer;
                            width: 56px;
                            height: 56px;
                        }
                        .spaceLeft, .spaceRight{
                            width: 76px;
                            height: 237px;
                            background: #4E5969;
                            border-radius: 4px 0 0 4px;
                            opacity: 0.5;
                        }
                        .spaceRight{
                            border-radius: 0 4px 4px 0;
                        }
                        .left{
                            width: 667px;
                            padding: 60px 32px;
                            height: 474px;
                            background: #4E5969;
                            border-radius: 8px 0 0 8px;
                            img{
                                width: 597px;
                            }
                        }
                        .right{
                            border-radius: 0 8px 8px 0;
                            height: 100%;
                            flex: 1;
                            background: #fff;
                            padding: 79px 30px 0;
                            img{
                                width: 124px;
                            }
                            .name{
                                margin-top: 32px;   
                                font-weight: bold;
                                color: #1D2129;
                                line-height: 28px;
                                font-size: 20px;
                            }
                            .content{
                                margin-top: 12px;
                                color: #86909C;
                                line-height: 24px;
                                font-size: 14px;
                            }
                        }
                    } 
                }
                
            }
            // .left{
            //     width: 256px;
            //     margin-right: 48px;
            //     overflow: hidden;
            //     .carousel{
            //         width: 256px;
            //         height: 420px;
            //         img{
            //             width: 205px;
            //         }
            //         .name{
            //             font-weight: bold;
            //             color: #1D2129;
            //             line-height: 40px;
            //             font-size: 28px;
            //             margin-top: 57px;
            //         }
            //         .content{
            //             color: #86909C;
            //             line-height: 24px;
            //             font-size: 16px;
            //             margin-top: 8px;
            //         }
            //     }
            // }
            // .right{
            //     flex: 1;
            //     height: 800px;
            //     padding: 40px;
            //     background: url("../../assets/header/bg_two.png") no-repeat center center;
            //     .tab-content{
            //         position: relative;
            //         height: 743px;
            //         img{
            //             position: absolute;
            //         }
            //         img:nth-child(1){
            //             top: 147px;
            //             left: 103px;
            //             z-index: 4;
            //         }
            //         img:nth-child(2){
            //             top: 103px;
            //             left: 60px;
            //             z-index: 3;
            //         }
            //     }
            //     .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
            //         color: #fff;
            //         opacity: 1;
            //     }
            //     .ant-tabs-tab-btn{
            //         color: #fff;
            //         opacity: 0.6;
            //         padding: 0 12px;
            //         line-height: 22px;
            //     }
            //     .ant-tabs-ink-bar{
            //         background: #FFFFFF;
            //     }
            //     .ant-tabs > .ant-tabs-nav{
            //         margin-bottom: 0;
            //     }
            //     .ant-tabs-top > .ant-tabs-nav::before{
            //         border-bottom: 1px solid rgba(247, 248, 250, 0.2);
            //     }
            // }
        }
        // .slick-dots{
        //     li.slick-active button, li.slick-active{
        //         background: #2EA7E0;
        //         width: 8px;
        //         height: 8px;
        //         border-radius: 50%;
        //     }
        //     li button, li{
        //         width: 8px;
        //         height: 8px;
        //         border-radius: 50%;
        //         background: rgba(46, 167, 224, 0.3);
        //     }
        // }
    }
    .block-three{
        background-color: #fff;
        width: 100%;
        padding: 60px 0 30px;
        display: flex;
        justify-content: center;
        .block-content{
            width: 1200px;
            .title{
                text-align: center;
                font-weight: bold;
                color: #1D2129;
                line-height: 45px;
                font-size: 32px;
            }
            .line{
                display: flex;
                margin-top: 32px;
                justify-content: center;
                .line-item{
                    width: 190px;
                    cursor: pointer;
                    .name{
                        text-align: center;
                        color: #758293;
                        line-height: 25px;
                        font-size: 18px;
                    }
                    .active{
                        color: #1E9BFF;
                    }
                    .line-dot{
                        margin-top: 12px;
                        display: flex;
                        align-items: center;
                        .line-dot-item{
                            width: 20px;
                            height: 20px;
                            background: rgba(201, 205, 212, 0.2);
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            div{
                                width: 10px;
                                height: 10px;
                                background: #C9CDD4;
                                border-radius: 50%;
                            }
                        }
                        .active-dot{
                            background: rgba(46, 167, 224, 0.2);
                            div{
                                background: #2EA7E0;
                            }
                        }
                        .line-line-item{
                            flex: 1;
                            height: 1px;
                            background: #E5E6E8;
                        }
                    }
                }
            }
            .tip{
                font-weight: 400;
                color: #4E5969;
                text-align: center;
                line-height: 20px;
                font-size: 14px;
                margin-top: 24px;
            }
            .img{
                margin-top: 18px;
                text-align: center;
            }
        }
    }
    .block-four{
        //background: linear-gradient(90deg, #6ABEFF 0%, #2175ED 100%);
        background: url("https://data.wedongmi.com/xinpi/official_website/png/bg_4.png") no-repeat center center;
        background-size: cover;
        height: 735px;
        width: 100%;
        padding: 86px 0 0;
        display: flex;
        justify-content: center;
        .block-content{
            width: 1200px;
            position: relative;
            .title{
                text-align: center;
                font-weight: 500;
                color: #fff;
                line-height: 45px;
                font-size: 32px;
            }
            .tip{
                font-weight: 400;
                color: #fff;
                text-align: center;
                line-height: 22px;
                font-size: 16px;
                margin-top: 10px;
            }
            .list{
                margin-top: 60px;
                .list-item{
                    margin-bottom: 51px;
                    display: flex;
                    align-items: center;
                    img{
                        margin-right: 6px;
                    }
                    .list-item-content{
                        div:nth-child(1){
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 25px;
                            font-size: 18px;
                        }
                        div:nth-child(2){
                            color: #FFFFFF;
                            line-height: 22px;
                            font-size: 16px;
                            margin-top: 6px;
                            opacity: 0.6;
                        }
                    }
                }
                .list-item:nth-last-child(1){
                    margin-bottom: 0;
                }
            }
            .img{
                position: absolute;
                right: 0;
                top: 160px;
            }
        }
    }
}
.version_1{
    .dots{
        width: 10px;
        right: 50px;
    }
    .dots li{
        margin: 8px 2px;
    }
    .dots li button, .dots li{
        width: 10px;
        height: 10px;
        background: #FFFFFF;
        border-radius: 50%;
        opacity: 0.5;
    }
    .dots li.slick-active button, .dots li.slick-active{
        width: 10px;
        height: 10px;
        opacity: 1;
    }
}
